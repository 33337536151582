<template>
	<div v-if="restaurant" class="overflow-x-hidden">
		<!-- Brasserie page message -->
		<div
			v-if="restaurant.acf.show_top_of_page_message"
			class="w-full py-4 xl:pb-16 px-5 lg:px-28 xl:px-40 2xl:px-64 bg-brand"
		>
			<div
				class="w-11/12 xl:w-full text-cream"
				v-html="restaurant.acf.top_of_page_message"
			></div>
		</div>
		<!-- End brasserie page message -->
		<div
			class="w-full xs:pb-0 py-10 px-5 lg:pl-28 xl:pl-40 2xl:pl-64 lg:pr-0 grid xs:text-center sm:grid-rows-1 lg:grid-rows-2 !xl:grid-rows-3 grid-cols-1 lg:grid-cols-2 lg:gap-y-8 relative"
			:class="getBackgroundColour('grainy')"
		>
			<div
				class="w-full h-[50%] bg-cream absolute left-0 bottom-0 z-10"
			></div>
			<div class="pb-20 lg:pb-10">
				<h2
					class="font-brand xs:text-center sm:text-left"
					v-html="restaurant.title"
				></h2>
				<div class="grid xs:grid-cols-1 sm:grid-cols-2 gap-x-4">
					<div class="xs:text-center sm:text-left">
						<h4>Contact</h4>
						<p
							class="font-brand"
							v-html="
								convertNewLinesToBreaks(restaurant.acf.address)
							"
						></p>
						<div
							v-if="restaurant.acf.tube_station"
							class="flex justify-start items-end"
						>
							<img
								loading="lazy"
								alt="Image"
								src="@/assets/tube.svg"
								class="max-w-full mr-2"
							/>
							<p class="mt-3 lg:mt-6">
								{{ restaurant.acf.tube_station }}
							</p>
						</div>
						<p class="mt-3 lg:mt-6">
							<a :href="`tel:${restaurant.acf.phone_number}`">{{
								restaurant.acf.phone_number
							}}</a>
						</p>
						<p class="mt-1">
							<a :href="`mailto:${restaurant.acf.email}`">{{
								restaurant.acf.email
							}}</a>
						</p>
						<a
							:href="`https://www.google.com/maps/search/?api=1&query=${encodeURI(
								'cote ' + restaurant.acf.location.address
							)}`"
							target="_blank"
							rel="noopener noreferrer"
							class="lg:inline-block font-copperplate font-bold border-b border-b-brand uppercase mt-3 lg:mt-6 inline-block cursor-pointer mr-4"
							>See on map</a
						>
						<!-- This was a quick fix, adding array index 2 - WP was returning a UTF encoded version of the title so I grabbed it from the path instead -->
						<a
							:href="`https://www.google.co.uk/maps/search/parking${encodeURI(
								' near cote ' + this.$route.path.split('/')[2]
							)}`"
							target="_blank"
							rel="noopener noreferrer"
							class="lg:inline-block font-copperplate font-bold border-b border-b-brand uppercase mt-3 lg:mt-6 inline-block cursor-pointer"
							>Parking</a
						>
					</div>
					<div class="xs:text-center md:text-left">
						<h4 class="font-brand uppercase">Opening Times</h4>
						<div
							v-for="(
								time, days, index
							) in restaurant.openingTimes"
							:key="index"
						>
							<p class="font-brand">{{ days }}</p>
							<p class="font-brand mt-1">{{ time }}</p>
						</div>
						<a
							:href="this.getBrasserieMenusLink($route.meta.id)"
							class="lg:inline-block font-copperplate font-bold border-b border-b-brand uppercase mt-3 lg:mt-6 inline-block cursor-pointer"
							>See menu</a
						>
					</div>
				</div>
			</div>
			<div class="block relative z-30 row-span-2 -mt-10 lg:mt-0">
				<div class="relative">
					<img
						loading="lazy"
						alt="Image"
						:src="
							restaurant.acf.main_image.sizes
								.cote_restaurant_header
						"
						class="max-w-full ml-auto relative z-20 xs:mr-0 -mr-5 lg:mr-0"
					/>
					<img
						loading="lazy"
						alt="Image"
						:src="
							restaurant.acf.small_image.sizes
								.cote_image_size_3_portrait
						"
						class="lg:hidden max-w-full w-56 ml-auto mr-5 xs:-mt-5 md:-mt-72 lg:-mt-20 c-image-offset bottom-left relative z-30"
						:class="
							getBackgroundColour(
								restaurant.acf.small_image_background_colour
									.value
							)
						"
					/>
				</div>
				<vertical-lines
					:lines="18"
					:border-width="'thin'"
					class="w-7/12 h-72 flex lg:hidden mt-16 absolute top-[35%] left-[-5%]"
				></vertical-lines>
			</div>
			<div class="w-full relative z-30">
				<div class="grid xs:grid-cols-1 lg:grid-cols-2 gap-x-4 mt-14">
					<div>
						<h5 class="mb-2 mt-5 xs:text-center lg:text-left">
							Amenities
						</h5>
						<div>
							<div
								v-for="(item, i) in restaurant.acf
									.facilities_amenities"
								:key="i"
								class="flex items-center xs:justify-center lg:justify-start py-1"
							>
								<div
									class="c-diamond"
									:class="{
										'c-yes': item,
										'opacity-50': !item,
									}"
								></div>
								<p
									class="lg:smaller font-brand capitalize m-0 mx-2 text-left"
									:class="{ 'opacity-50': !item }"
									v-html="
										getFacilitiesAmenitiesLabel(i, item)
									"
								></p>
								<div
									class="c-diamond xs:block lg:hidden"
									:class="{
										'c-yes': item,
										'opacity-50': !item,
									}"
								></div>
							</div>
						</div>
					</div>
					<div v-if="restaurant.acf.show_guide_section">
						<h5 class="mb-2 mt-5 xs:text-center lg:text-left">
							Guide
						</h5>
						<div>
							<div
								v-for="(item, i) in restaurant.acf
									.facilities_guide"
								:key="i"
								class="flex items-center xs:justify-center lg:justify-start py-1"
							>
								<div
									class="c-diamond"
									:class="{
										'c-yes': item,
										'opacity-50': !item,
									}"
								></div>
								<p
									class="lg:smaller font-brand capitalize m-0 mx-2 text-left"
									:class="{ 'opacity-50': !item }"
								>
									{{ !item ? 'No ' : '' }}
									{{ i.replaceAll('_', ' ') }}
								</p>
								<div
									class="c-diamond xs:block lg:hidden"
									:class="{
										'c-yes': item,
										'opacity-50': !item,
									}"
								></div>
							</div>
						</div>
					</div>
				</div>
				<div class="w-full xs:text-center lg:text-left lg:mt-4">
					<router-link
						to="/contact"
						class="standard-button my-3"
						:class="getButtonStyle('transparent')"
						>Ask a question</router-link
					>
				</div>
				<img
					loading="lazy"
					alt="Image"
					:src="
						restaurant.acf.small_image.sizes
							.cote_image_size_3_portrait
					"
					class="hidden lg:block max-w-full lg:w-60 xl:w-72 2xl:w-auto c-image-offset bottom-left absolute top-14 left-[90%]"
					:class="
						getBackgroundColour(
							restaurant.acf.small_image_background_colour.value
						)
					"
				/>
			</div>
		</div>
		<div
			class="py-10 lg:mt-0 xl:-mt-20 xl:-mt-72 2xl:-mt-48 pl-5 lg:pl-0 lg:pr-28 xl:pr-40 2xl:pr-64 relative z-20"
			:class="getBackgroundColour('cream')"
		>
			<!-- Remove desktop reviews section for now -->
			<div class="hidden xl:grid grid-cols-4 gap-4 xl:mt-8 mr-5 lg:mr-0">
				<div class="col-start-5 my-10"></div>
			</div>

			<div class="relative">
				<carousel
					:settings="settings"
					:breakpoints="breakpoints"
					class="relative z-20 xl:mt-32 c-dark-nav c-overlay-nav lg:w-11/12"
				>
					<slide
						class="flex !justify-start !items-start px-2 lg:px-0"
						v-for="(item, index) in restaurant.acf.slideshow"
						:key="index"
					>
						<img
							loading="lazy"
							alt="Image"
							:src="item.image.url"
							class="hidden lg:block lg:mt-10 h-30"
						/>
						<img
							loading="lazy"
							alt="Image"
							:src="item.image.sizes.cote_image_size_6_landscape"
							class="block lg:hidden max-w-full"
						/>
					</slide>
					<template #addons>
						<Navigation />
					</template>
				</carousel>
				<vertical-lines
					:lines="24"
					:border-width="'thick'"
					class="hidden lg:flex w-4/12 h-[97%] flex absolute lg:-top-[10%] left-[85%]"
				></vertical-lines>

				<!-- mobile -->
				<div class="grid grid-cols-2 grid-rows-1 lg:hidden relative">
					<div class="justify-self-start">
						<vertical-lines
							:lines="20"
							:border-width="'thin'"
							class="lg:hidden w-4/12 h-44 flex absolute xs:top-[-80px] top-[-45%] -left-5"
						></vertical-lines>
					</div>
					<!-- Remove mobile reviews section for now
          <div class="justify-self-end pr-5" v-if="this.$store.getters.getRestaurantRating(placeId)">
            <h5 class="text-left">Google Score</h5>
            <h3 class="text-left mt-2">{{this.$store.getters.getRestaurantRating(placeId) || 'No Rating Yet' }} / 5</h3>
            <div class="flex justify-start items-center mt-2">
              <star-icon class="w-6 mx-1 ml-0" :class="getGoogleRatingClass(1)"></star-icon>
              <star-icon class="w-6 mx-1" :class="getGoogleRatingClass(2)"></star-icon>
              <star-icon class="w-6 mx-1" :class="getGoogleRatingClass(3)"></star-icon>
              <star-icon class="w-6 mx-1" :class="getGoogleRatingClass(4)"></star-icon>
              <star-icon class="w-6 mx-1" :class="getGoogleRatingClass(5)"></star-icon>
            </div>
            <a :href="`https://search.google.com/local/reviews?placeid=${placeId}`" target="_blank"> <p class="smaller text-left">See reviews on Google</p></a>
          </div> -->
				</div>
				<!-- /mobile -->
			</div>
		</div>
		<!--  Global layout blocks  -->
		<default-view
			v-if="globalRestaurantContent"
			:embedded="true"
			:blocks="globalRestaurantContent"
			:is-restaurant-page="true"
			:restaurant-id="restaurant.id"
		></default-view>
		<!--  Restaurant specific layout blocks  -->
		<default-view
			v-if="restaurant.acf.blocks"
			:embedded="true"
			:blocks="restaurant.acf.blocks"
		></default-view>
		<div
			class="px-5 lg:px-28 xl:px-40 2xl:px-64 py-5 lg:py-8 bg-cream xs:relative xs:z-20"
		>
			<h2 class="mb-8 lg:mb-20 lg:text-left">You might also like</h2>
			<div
				class="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-12 2xl:gap-20"
			>
				<div
					v-for="(
						nearbyRestaurant, i
					) in restaurant.nearbyRestaurants"
					:key="i"
					class="text-center lg:flex flex-col justify-between"
				>
					<img
						loading="lazy"
						alt="Image"
						:src="nearbyRestaurant.restaurant_preview"
						class="w-full md:w-10/12 mx-auto mb-1"
					/>
					<h5>{{ nearbyRestaurant.post_title }}</h5>
					<p class="w-1/2 mx-auto">
						{{ nearbyRestaurant.one_line_description }}
						{{ nearbyRestaurant.distance }} miles from
						<span v-html="restaurant.title"></span>
					</p>
					<div class="flex flex-col items-center">
						<button
							type="button"
							class="standard-button w-44 lg:w-60"
							:class="getButtonStyle('navy')"
							@click="
								openLinkInNewTab(
									`https://www.google.com/maps/search/?api=1&query=${encodeURI(
										'cote ' + nearbyRestaurant.address
									)}`
								)
							"
						>
							See on map
						</button>
						<router-link
							:to="getBrasserieLink(nearbyRestaurant.ID)"
							class="standard-button mt-3 w-44 lg:w-60"
							:class="getButtonStyle('transparent')"
							>Explore</router-link
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DateTime } from 'luxon';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { StarIcon } from '@heroicons/vue/solid';
import { mapGetters } from 'vuex';
import verticalLines from '@/components/VerticalLines.vue';
import DefaultView from '@/views/DefaultView.vue';
import SplashScreen from '@/components/layouts/SplashScreen.vue';

export default {
	name: 'RestaurantView',
	components: {
		SplashScreen,
		DefaultView,
		verticalLines,
		Carousel,
		Slide,
		Navigation,
		StarIcon,
	},
	data() {
		return {
			contentLoaded: false,
			settings: {
				itemsToShow: 2,
				snapAlign: 'start',
				wrapAround: true,
			},
			breakpoints: {
				640: {
					itemsToShow: 3,
					snapAlign: 'start',
				},
				1024: {
					itemsToShow: 1,
					snapAlign: 'start',
				},
			},
		};
	},
	emits: ['contentLoaded', 'displayPlaceholder'],
	computed: {
		openingTimes() {
			return {
				monday: {
					open: this.restaurant.acf.monday_open,
					close: this.restaurant.acf.monday_close,
				},
				tuesday: {
					open: this.restaurant.acf.tuesday_open,
					close: this.restaurant.acf.tuesday_close,
				},
				wednesday: {
					open: this.restaurant.acf.wednesday_open,
					close: this.restaurant.acf.wednesday_close,
				},
				thursday: {
					open: this.restaurant.acf.thursday_open,
					close: this.restaurant.acf.thursday_close,
				},
				friday: {
					open: this.restaurant.acf.friday_open,
					close: this.restaurant.acf.friday_close,
				},
				saturday: {
					open: this.restaurant.acf.saturday_open,
					close: this.restaurant.acf.saturday_close,
				},
				sunday: {
					open: this.restaurant.acf.sunday_open,
					close: this.restaurant.acf.sunday_close,
				},
			};
		},
		globalRestaurantContent() {
			return this.$store.state.globalRestaurantPagesContent;
		},

		placeId() {
			return this.restaurant.acf.google_place_id;
		},

		restaurant() {
			return this.$store.getters.getRestaurantContentById(
				this.$route.meta.id
			);
		},
		...mapGetters(['getRestaurantRating']),
	},

	methods: {
		async getContent() {
			if (
				!this.$store.getters.checkIfRestaurantContentLoaded(
					this.$route.meta.id
				)
			) {
				if (this.$store.getters.isFirstSplashScreenHidden) {
					this.$emit('displayPlaceholder');
				}
				await this.$store.dispatch('getRestaurant', {
					id: this.$route.meta.id,
				});
			}
			this.$emit('contentLoaded');
		},

		openLinkInNewTab(link) {
			window.open(link, '_blank');
		},

		getFacilitiesAmenitiesLabel(fieldName, active) {
			let label = active ? '' : 'No ';

			/* each case here should match up with each of the fields within the Brasserie facilities_amenities GroupField */
			switch (fieldName) {
				case 'baby_changing':
					label += 'Baby Changing';
					break;

				case 'bar_area':
					label += 'Bar Area';
					break;

				case 'highchairs':
					label += 'Highchairs';
					break;

				case 'outside_seated_bar':
					label += 'Outside Seating';
					break;

				case 'private_dining':
					label += 'Private Dining';
					break;

				case 'wifi':
					label += 'Wi-Fi';
					break;

				default:
					label += fieldName.replaceAll('_', ' ');
					break;
			}

			return label;
		},

		getOpeningTimesText() {
			// Format the time as a single line if every day has the same opening hours.
			// Otherwise, show each day individually.
			const openingTimesArray = Object.values(this.openingTimes);
			let open = null;
			let close = null;
			let formattedTimes = null;
			const theSame = openingTimesArray.every((time) => {
				if (open !== null && close !== null) {
					if (time.open !== open || time.close !== close) {
						return false;
					}
				}
				if (open === null) {
					open = time.open;
				}
				if (close === null) {
					close = time.close;
				}
				return true;
			});
			if (theSame) {
				formattedTimes = `Monday - Sunday<br>${DateTime.fromISO(
					this.openingTimes.monday.open
				).toLocaleString(DateTime.TIME_SIMPLE)} - ${DateTime.fromISO(
					this.openingTimes.monday.close
				).toLocaleString(DateTime.TIME_SIMPLE)}`;
			} else {
				let times = '';
				openingTimesArray.forEach((time, index) => {
					const i = String(index + 1);
					times += `${DateTime.fromFormat(i, 'E').toFormat(
						'EEEE'
					)}<br>${DateTime.fromISO(time.open).toLocaleString(
						DateTime.TIME_SIMPLE
					)} - ${DateTime.fromISO(time.close).toLocaleString(
						DateTime.TIME_SIMPLE
					)}${index !== 6 ? '<br>' : ''}`;
					formattedTimes = times;
				});
			}
			return formattedTimes;
		} /* ,
    getGoogleRatingClass (star) {
      return this.$store.getters.getRestaurantRating(this.placeId) >= star ? 'text-brand' : 'text-brand/50'
    } */,
	},

	created() {
		this.getContent();
	},
};
</script>

<style lang="scss" scoped>
.c-diamond {
	width: 1vw;
	max-width: 20px;
	min-width: 10px;
	height: 1vw;
	max-height: 20px;
	min-height: 10px;
	margin: 0 5px 0 10px;
	position: relative;
	transform: scaleY(0.5) rotate(45deg) translateY(50%);
	border: 1px solid #0a2036;
}

.c-diamond.c-yes {
	background-color: #0a2036;
}
</style>
