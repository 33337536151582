class OpenTable {
	#apiEndpoint;

	constructor() {
		this.#apiEndpoint = process.env.VUE_APP_BOOKING_SERVICE_URL;
	}

	/**
	 * Create a freshdesk ticket for large party booking
	 * @param {Object} reservationData
	 * @returns {Promise<Response>}
	 */
	createEnquiry(reservationData) {
		return this.#api('/freshdesk-reservation', 'POST', reservationData);
	}


	/**
	 * Lock a reservation timeslot for 5 mins with the booking-service api
	 * @param {*} reservationData
	 * @returns
	 */
	lockReservation(reservationData) {
		return this.#api('/open-table/availability', 'POST', reservationData);
	}

	/**
	 * Release a reservation timeslot with the booking-service api
	 * @param {*} reservationData
	 * @returns
	 */
	releaseReservation(reservationData) {
		return this.#api('/open-table/availability', 'DELETE', reservationData);
	}

	/**
	 * Make a reservation with open table using the booking-service api
	 * @param {*} reservationData
	 * @returns
	 */
	makeReservation(reservationData) {
		return this.#api('/open-table/reservation', 'POST', reservationData);
	}

	/**
	 * Get a list of experiences for a restaurant
	 * @param {*} restaurant_id
	 * @returns
	 */
	getExperiences(restaurant_id) {
		return this.#api(`/open-table/experiences?rid=${restaurant_id}`);
	}

  /**
   * Get policies from open table
   * @param {*} restaurant_id
   * @param {*} date
   * @param {*} time
   * @param {*} party_size
   * @returns
   */
	getPolicies(restaurant_id, date, time, party_size) {
		return this.#api(`/open-table/policies?rid=${restaurant_id}&date=${date}&time=${time}&party_size=${party_size}`);
	}

	#api(endpoint, method = 'GET', data = null) {
		const args = {
			method,
		};
		if (method !== 'GET') {
			if (data !== null) {
				args.body = JSON.stringify(data);
			}
		}
		return fetch(this.#apiEndpoint + endpoint, args);
	}
}

export default new OpenTable();
