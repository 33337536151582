<template>
	<div
		@click="toggleFilterVisibility"
		@keydown.enter="toggleFilterVisibility"
		v-show="events.length"
		tabindex="0"
		class="w-full bg-[#253647] flex justify-between items-center px-5 lg:px-10 py-6 sticky top-16 z-10 max-h-[64px] xl:max-h-[80px] cursor-pointer"
	>
		<h5 class="text-[#d6b045] font-bold uppercase mt-0">
			<img
				loading="lazy"
				alt="Filter icon"
				src="@/assets/filter-open.svg"
				class="w-6 lg:w-10 inline mr-1"
			/>Filter the events
		</h5>
	</div>
	<div
		v-if="events.length && showFilter"
		class="md:grid grid-cols-4 sm:block bg-brand text-cream mx-4 mb-4"
	>
		<div class="p-5 w-full">
			<h5 class="text-cream w-10/12 mx-auto mt-0 mb-2 smaller">
				Event name
			</h5>
			<div class="search-select relative">
				<div class="search-select-input">
					<img
						loading="lazy"
						class="pointer-events-none ml-1.5 w-[16px] right-[4px] top-[10px] absolute"
						alt="Filter by event name"
						src="@/assets/booking-widget-down-arrow-cream.svg"
					/>

					<label for="title-search" hidden>Fitler by name</label>
					<input
						ref="selectListInput"
						type="text"
						id="title-search"
						v-model="titleSearch"
						placeholder="View all"
						@focus="searchInputFocused = true"
						class="border border-cream px-2 py-2 h-9 text-cream w-full bg-transparent placeholder:font-parisine placeholder:font-thin placeholder:text-base placeholder:text-cream rounded-none"
					/>
				</div>
				<ul
					class="bg-brand py-2 border boarder-cream select-list left-0 right-0 top-10 absolute"
					ref="eventSelectList"
					v-show="searchInputFocused"
				>
					<li
						class="block min-h-24 cursor-pointer"
						@click="titleSearch = ''"
						@keypress="selectEventtitleSearch = ''"
					>
						View all
					</li>

					<li
						class="block min-h-24 cursor-pointer"
						v-for="event in uniqueActiveEvents"
						:key="event"
						@click="selectEvent(event)"
						@keypress="selectEvent(event)"
					>
						{{ event }}
					</li>

					<li
						class="block min-h-24 cursor-pointer"
						v-if="!uniqueActiveEvents.length"
					>
						No events with this name
					</li>
				</ul>
			</div>
		</div>
		<div class="p-5 w-full">
			<h5 class="text-cream w-10/12 mx-auto mt-0 mb-2 smaller">Price</h5>
			<label for="price-search" hidden>Filter by price</label>
			<select
				id="price-search"
				v-model="priceSearch"
				class="events-select border border-cream px-2 h-9 text-cream w-full bg-brand appearance-none custom-events-dropdown-style"
			>
				<option value="">All prices</option>
				<option :value="freeText">{{ freeText }}</option>
				<option :value="paidText">{{ paidText }}</option>
			</select>
		</div>
		<div class="p-5 w-full">
			<h5 class="text-cream w-10/12 mx-auto mt-0 mb-2 smaller">
				Location
			</h5>
			<label for="location-search" hidden>Filter by location</label>
			<select
				id="location-search"
				v-model="locationSearch"
				class="border border-cream px-2 h-9 text-cream w-full bg-brand appearance-none custom-events-dropdown-style"
			>
				<option value="">View all</option>
				<option v-for="location in locations" :key="location">
					<span v-html="location"></span>
				</option>
			</select>
		</div>
		<div class="p-5 w-full">
			<h5 class="text-cream w-10/12 mx-auto mt-0 mb-2 smaller">
				Event Type
			</h5>
			<label for="type-search" hidden>Filter by type</label>

			<select
				id="type-search"
				v-model="typeSearch"
				class="border border-cream px-2 h-9 text-cream text-small w-full bg-brand appearance-none custom-events-dropdown-style"
			>
				<option value="">All</option>
				<option v-for="type in types" :key="type">
					{{ type }}
				</option>
			</select>
		</div>
	</div>
	<div class="w-full py-10 bg-noise-pale">
		<div class="container bg-noise-pale mx-auto px-4">
			<h1 class="text-brand lg:w-full text-center mt-0 mb-12">Events</h1>

			<p v-if="!events.length" class="flex h-screen justify-center mt-32">
				Sorry, we currently don’t have any events.
			</p>

			<div class="flex flex-wrap">
				<div
					v-for="event in paginatedEvents"
					:key="event.id"
					class="w-full md:w-1/2 lg:w-1/3 p-4"
				>
					<div class="bg-white shadow-md overflow-hidden h-full">
						<img
							loading="lazy"
							class="w-full h-48 object-cover"
							:src="getImage(event)"
							:alt="event.title"
						/>

						<div
							class="p-4 flex flex-col justify-between lg:h-82 h-64"
						>
							<h2
								class="text-lg font-semibold text-gray-800 mb-3 truncate ..."
							>
								<span v-html="event.title"></span>
							</h2>

							<div>
								<p
									v-if="event.all_day"
									class="mt-0 font-bold text-xl"
								>
									{{
										this.getDateOnlyFormat(event.start_date)
									}}
									<span
										v-if="
											!isSameDate(
												event.start_date,
												event.end_date
											)
										"
									>
										-
										{{
											this.getDateOnlyFormat(
												event.end_date
											)
										}}
									</span>
									<br />
									All day
								</p>

								<p v-else class="mt-0 font-bold text-xl">
									{{ computeEventTime(event) }}
								</p>

								<p class="text-gray-600">
									<b class="font-bold">Location: </b>
									<span
										v-html="showEventLocationText(event)"
									></span>
								</p>
								<p
									v-if="shouldShowPrice(event)"
									class="text-gray-600"
								>
									<b class="font-bold">Price:</b>
									{{ event.cost }}
								</p>
							</div>

							<router-link
								v-bind:to="'/events/' + event.id"
								class="standard-button bg-mustard text-brand border border-mustard mt-5 self-center"
							>
								Read more
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<p
				v-if="!paginatedEvents.length && events.length"
				class="flex h-screen justify-center mt-32"
			>
				Sorry, we currently don’t have any events matching your filters.
			</p>

			<div v-if="totalPage > 1" class="flex justify-center my-4">
				<div class="flex items-center">
					<button
						type="button"
						:disabled="currentPage === 1"
						class="standard-button border border-gray-400 text-gray-600 hover:text-gray-800 px-4 py-2"
						@click="goToPage(currentPage - 1)"
					>
						Previous
					</button>
					<button
						type="button"
						v-for="page in totalPage"
						:key="page"
						class="standard-button border border-gray-400 text-gray-600 hover:text-gray-800 px-4 py-2"
						:class="{ 'bg-gray-200': currentPage === page }"
						@click="goToPage(page)"
					>
						{{ page }}
					</button>
					<button
						type="button"
						:disabled="currentPage === totalPage"
						class="standard-button border border-gray-400 text-gray-600 hover:text-gray-800 px-4 py-2"
						@click="goToPage(currentPage + 1)"
					>
						Next
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import defaultImage from '@/assets/default-event-picture.jpg';
import { NATIONAL, EVENT_COST } from '@/constants/globalConstants';

export default {
	name: 'EventsView',
	emits: ['contentLoaded', 'displayPlaceholder'],
	computed: {
		events() {
			return this.$store.state.events ?? [];
		},
		// This should return all location names for the filtering dropdown
		locations() {
			const eventLocations = this.events
				.map((event) => {
					if (this.isNational(event)) {
						return NATIONAL;
					} else {
						return event.event_locations.map((location) => {
							return this.$store.getters.getRestaurantById(
								location
							).post_title;
						});
					}
				})
				.flat();

			return new Set(eventLocations);
		},
		types() {
			const types = new Set();

			this.events.forEach(({ categories }) => {
				categories.forEach(({ name }) => {
					types.add(name);
				});
			});

			return types;
		},
		filteredEvents() {
			this.eventsToDisplay = this.events;
			this.filterByTitle()
				.filterByPrice()
				.filterByLocation()
				.filterByType();

			return this.eventsToDisplay;
		},
		paginatedEvents() {
			const start = (this.currentPage - 1) * this.pageSize;
			const end = start + this.pageSize;
			return this.filteredEvents.slice(start, end);
		},
		totalPage() {
			return Math.ceil(this.filteredEvents.length / this.pageSize);
		},
	},
	data() {
		return {
			titleSearch: '',
			priceSearch: '',
			locationSearch: '',
			typeSearch: '',
			currentPage: 1,
			pageSize: 6,
			eventsToDisplay: [],
			searchInputFocused: false,
			uniqueActiveEvents: [],
			showFilter: false,
		};
	},
	methods: {
		showEventLocationText(event) {
			let result = event.event_type;

			if (event.event_type === 'Single') {
				const locationId = event.event_locations[0];
				result =
					this.$store.getters.getRestaurantById(
						locationId
					).post_title;
			}

			return result;
		},
		clearSearchInputFocus() {
			this.searchInputFocused = false;
		},
		getUniqueActiveEventsNames() {
			this.uniqueActiveEvents = Array.from(
				new Set(this.events.map(({ title }) => title))
			).filter((event) =>
				event.toLowerCase().includes(this.titleSearch.toLowerCase())
			);
		},
		selectEvent(name) {
			this.titleSearch = name;
			this.clearSearchInputFocus();
		},
		toggleEventSelectList() {
			this.$refs.eventSelectList.classList.toggle('hidden');
		},
		async getAllEvents() {
			if (
				!this.$store.getters.checkIfPageContentLoaded(
					this.$route.meta.id
				)
			) {
				if (this.$store.getters.isFirstSplashScreenHidden) {
					this.$emit('displayPlaceholder');
				}
				await this.$store.dispatch('getAllEvents');
				this.$emit('contentLoaded');
			}
		},
		goToPage(page) {
			this.currentPage = page;
			window.scrollTo(0, 0);
		},
		getImage(event) {
			return event?.image.url ?? defaultImage;
		},
		shouldShowPrice(event) {
			return event.cost;
		},
		isFree(event) {
			return !event.cost || event.cost === this.freeText;
		},
		filterByTitle() {
			this.eventsToDisplay = this.eventsToDisplay.filter(({ title }) =>
				title.toLowerCase().includes(this.titleSearch.toLowerCase())
			);

			return this;
		},
		filterByPrice() {
			if (this.priceSearch) {
				const shouldDisplayFree = this.priceSearch === this.freeText;

				this.eventsToDisplay = this.eventsToDisplay.filter(
					(event) => this.isFree(event) === shouldDisplayFree
				);
			}

			return this;
		},
		filterByLocation() {
			if (this.locationSearch) {
				this.eventsToDisplay = this.eventsToDisplay.filter((event) => {
					if (event.event_type === 'All Locations') {
						return (
							this.locationSearch.toLowerCase() ===
							NATIONAL.toLowerCase()
						);
					} else {
						return event.event_locations?.some((location) => {
							return (
								this.$store.getters
									.getRestaurantById(location)
									?.post_title.toLowerCase() ===
								this.locationSearch.toLowerCase()
							);
						});
					}
				});
			}
			return this;
		},
		filterByType() {
			if (this.typeSearch) {
				this.eventsToDisplay = this.eventsToDisplay.filter((event) =>
					event.categories.some(
						(category) =>
							category.name.toLowerCase() ===
							this.typeSearch.toLowerCase()
					)
				);
			}

			return this;
		},
		isNational(event) {
			return event.event_type === 'All Locations';
		},
		toggleFilterVisibility() {
			this.showFilter = !this.showFilter;
			window.scrollTo({ top: 0, behavior: 'smooth' });
		},
		computeEventTime(event) {
			if (event.show_event_time === 'yes') {
				if (event.show_end_time === 'yes') {
					return this.getDateTimeRange(
						event.start_date,
						event.end_date
					);
				}

				return this.getDateWithTimeFormat(event.start_date);
			}

			return this.getDateOnlyFormat(event.start_date);
		},
	},
	watch: {
		events(value) {
			this.uniqueActiveEvents = Array.from(
				new Set(value.map(({ title }) => title))
			);
		},
		titleSearch() {
			this.getUniqueActiveEventsNames();
		},
	},
	mounted() {
		document.addEventListener('click', (event) => {
			if (event.target !== this.$refs.selectListInput) {
				this.clearSearchInputFocus();
			}
		});
	},
	created() {
		this.getAllEvents();
		this.freeText = EVENT_COST.FREE;
		this.paidText = EVENT_COST.PAID;
	},
};
</script>
