<template>
	<div
		id="menuLocationSelector"
		class="bg-[#253647] xl:bg-transparent px-4 md:px-8 pt-2 pb-1 md:pb-2 flex justify-start items-center fixed w-full z-70 justify-center pointer-events-none"
	>
		<img
			loading="lazy"
			alt="Image"
			src="@/assets/location.svg"
			class="w-7 md:w-auto"
		/>
		<h5
			class="text-[#d6b045] mt-0 ml-1.5 mr-1.5 md:text-[18px] md:leading-[24px] md:tracking-[0.9px]"
		>
			{{ $route.meta.viewTitle }}
		</h5>
		<router-link to="/menus" :class="'pointer-events-auto'">
			<img
				loading="lazy"
				alt="Image"
				src="@/assets/edit.svg"
				class="w-7 md:w-auto"
			/>
		</router-link>
	</div>
	<div class="w-full bg-noise-pale">
		<div v-pre id="tkMenu" class="k10-html-container"></div>
	</div>
	<div id="BodyScripts"></div>
</template>

<script>
import exponea from '@/plugins/exponea';
import SplashScreen from '@/components/layouts/SplashScreen.vue';

export default {
	name: 'MenuView',
	components: {
		SplashScreen,
	},
	data() {
		return {
			contentLoaded: false,
			menuViewWatcherLoaded: false,
		};
	},
	methods: {
		exponeaTrackMenuView(quandooId, menuName) {
			this.$store.dispatch('getCookieConsent').then((resp) => {
				if (resp.consent === 'true') {
					exponea.track('menu_view', {
						quandoo_id: quandooId,
						menu_name: menuName,
					});
				}
			});
		},
		removeClickTrackers() {
			const self = this;
			const menuTitles = document.getElementsByClassName(
				'k10-menu-selector__option-name-wrapper'
			);

			if (menuTitles.length > 0) {
				menuTitles.forEach((currentValue) => {
					currentValue.removeEventListener('click', function () {
						self.exponeaTrackMenuView(
							self.$route.meta.quandooId,
							this.innerText
						);
					});
				});

				this.menuViewWatcherLoaded = false;
			}
		},

		async loadScriptsAfterDependencies() {
			await this.loadExternalScript();

			if (this.$store.state.menuTiers.length < 1) {
				const resp = await this.$store.dispatch('getMenuTiers');
				this.loadScripts(resp.menuTiers);
			} else {
				this.loadScripts(this.$store.state.menuTiers);
			}
		},

		loadExternalScript() {
			return new Promise((resolve, reject) => {
				const tenKitesScript = document.createElement('script');
				tenKitesScript.setAttribute(
					'src',
					'https://menus.tenkites.com/Scripts/_k10.min.js?sbk=20160720'
				);
				tenKitesScript.onload = resolve;
				tenKitesScript.onerror = reject;
				document.head.appendChild(tenKitesScript);
			});
		},

		loadScripts(menuTiers) {
			this.removeClickTrackers();

			let menu = null;
			let tierSubMenuCodes = null;
			menuTiers.every((tier) => {
				if (
					tier.available_restaurants.find(
						(r) => r.ID === this.$route.meta.id
					) !== undefined
				) {
					menu = tier.menu_link;
					tierSubMenuCodes = tier.tier_sub_menu_codes;
					return false;
				}
				return true;
			});

			const self = this;

			if (window.location.href.indexOf('menucode=') !== -1) {
				let subMenuCode = this.$store.getters.getMenuCode;
				const defaultSubMenuCodes =
					this.$store.getters.getDefaultSubMenuCodes;

				// get semantic name
				// search for semantic name in tier
				const tierSubMenuCode = tierSubMenuCodes.find(
					(code) => code.sub_menu_slug === subMenuCode
				);

				// If the tier has it's own sub menu code for the query
				if (tierSubMenuCode) {
					// use that code
					subMenuCode = tierSubMenuCode.tenkites_code;
					// if not in tier, search for semantic name in default
				} else if (
					typeof defaultSubMenuCodes[subMenuCode] !== 'undefined'
				) {
					subMenuCode = defaultSubMenuCodes[subMenuCode];
				}

				// if not in default, append code directly
				if (subMenuCode.length > 0) {
					menu = `${menu}?menucode=${subMenuCode}`;
				}
			}

			// Ten kites code
			$k10(() => {
				$k10.ajax({
					url: menu,
					type: 'GET',
					crossDomain: true,
					success(responseData, textStatus, jqXHR) {
						const $k10tkHtml = $k10('<div/>', {
							html: responseData,
						});
						const headContent = $k10tkHtml
							.find('.tk-html-header-scripts')
							.html();
						const bodyContent = $k10tkHtml
							.find('.tk-html-body')
							.html();
						const bodyScripts = $k10tkHtml
							.find('.tk-html-body-scripts')
							.html();
						$k10('head').append(headContent);
						$k10('.k10-html-container').html(bodyContent);
						setTimeout(() => {
							$k10('#BodyScripts').html(bodyScripts);
							self.contentLoaded = true;
						}, 50);
					},
					error(responseData, textStatus, errorThrown) {
						console.log('POST failed');
					},
				});
			});
		},
	},

	async created() {
		try {
			await this.loadScriptsAfterDependencies();
		} catch (error) {
			console.error('Error loading external script:', error);
		}
	},
	watch: {
		contentLoaded(value) {
			if (value && !this.menuViewWatcherLoaded) {
				setTimeout(() => {
					const self = this;
					const menuTitles = document.getElementsByClassName(
						'k10-menu-selector__option-name-wrapper'
					);

					if (menuTitles.length > 0) {
						menuTitles.forEach((currentValue) => {
							currentValue.addEventListener('click', function () {
								self.exponeaTrackMenuView(
									self.$route.meta.quandooId,
									this.innerText
								);
							});
						});
						this.menuViewWatcherLoaded = true;
					}
				}, 750);
			}
		},
	},
	beforeUnmount() {
		this.removeClickTrackers();
	},
};
</script>
