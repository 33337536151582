<template>
	<div v-if="!embedded">
		<div v-if="page && page.hasOwnProperty('blocks')">
			<div v-for="layout in page.blocks" :key="layout.acf_fc_layout">
				<component
					v-if="layout.is_active"
					:is="getBlockName(layout.acf_fc_layout)"
					:ref="getBlockName(layout.acf_fc_layout)"
					:fields="layout"
					@section-change="(name) => scrollToSection(name)"
					@onFaqSearchEntered="
						(searchValue) => searchFaqs(searchValue)
					"
				></component>
			</div>
		</div>
	</div>
	<div v-if="embedded">
		<div v-for="layout in blocks" :key="layout.acf_fc_layout">
			<component
				v-if="!isRestaurantPage && layout.is_active"
				:is="getBlockName(layout.acf_fc_layout)"
				:fields="layout"
			></component>
			<component
				v-if="
					isRestaurantPage &&
					layout.is_active &&
					checkGlobalRestaurantContentDisplay(
						layout.brasserie_visibility_type,
						layout.brasserie_visibility_restaurants
					)
				"
				:is="getBlockName(layout.acf_fc_layout)"
				:fields="layout"
			></component>
		</div>
	</div>
</template>

<script>
import SplashScreen from '@/components/layouts/SplashScreen.vue';

export default {
	name: 'DefaultView',
	components: {
		SplashScreen,
	},
	props: {
		embedded: {
			type: Boolean,
			default: false,
		},
		blocks: {
			type: Object,
		},
		isRestaurantPage: {
			type: Boolean,
			default: false,
		},
		restaurantId: {
			type: Number,
			default: 0,
		},
	},
	emits: ['contentLoaded', 'displayPlaceholder'],
	data: () => ({
		displayPlaceholder: false,
	}),
	computed: {
		page() {
			return this.$store.getters.getPageContentById(this.$route.meta.id);
		},
		contentLoaded() {
			return (
				this.page &&
				Object.prototype.hasOwnProperty.call(this.page, 'blocks')
			);
		},
	},
	methods: {
		async getContent () {
			if (!this.embedded) {
				if (!this.$store.getters.checkIfPageContentLoaded(this.$route.meta.id)) {
					if (this.$store.getters.isFirstSplashScreenHidden) {
						this.$emit('displayPlaceholder')
					}
				await this.$store.dispatch('getPage', { id: this.$route.meta.id })
				this.$emit('contentLoaded')
				}
			}
		},
		getBlockName(name) {
			let string = '';
			const items = name.split('_');
			items.forEach((i) => {
				string += i.charAt(0).toUpperCase() + i.slice(1).toLowerCase();
			});
			return `${string}Block`;
		},
		setRef(type, name) {
			return JSON.stringify({
				layoutType: this.getBlockName(type),
				layoutName: name,
			});
		},
		searchFaqs(searchValue) {
			const faqComponent = this.$refs.FaqsBlock[0];
			if (faqComponent) {
				const faqBlock = document.getElementById('faqBlock');
				faqBlock.scrollIntoView({ behavior: 'smooth' });
				faqComponent.search(searchValue);
			}
		},
		scrollToSection(name) {
			const el = document.getElementById(name);
			window.scrollTo({
				top: el.offsetTop - 150,
				behavior: 'smooth',
			});
		},
		checkGlobalRestaurantContentDisplay(mode, restaurants) {
			if (mode === 'all') {
				return true;
			}
			if (mode === 'none') {
				return false;
			}
			if (mode === 'only') {
				if (
					restaurants.filter((r) => r === this.restaurantId).length >
					0
				) {
					return true;
				}
			}
			if (mode === 'except') {
				if (
					restaurants.filter((r) => r === this.restaurantId).length >
					0
				) {
					return false;
				}
			}
		},
	},
	created () {
    this.getContent()
  }
};
</script>

<style lang="scss"></style>
