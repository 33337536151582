import store from '@/store/store';
import wordpress from '@/plugins/wordpress';
import { DateTime } from 'luxon';

class Exponea {
	#cookieConsent;

	constructor() {
		this.#cookieConsent = store.state.cookieConsent;
		store.watch(
			(state) => state.cookieConsent,
			(newValue, oldValue) => {
				this.#cookieConsent = newValue;
			}
		);
	}

	/**
	 * Link session to customer in Exponea
	 * @param email
	 */
	async identify(email) {
		// if (this.#cookieConsent === 'true' && window.exponeaLoaded) {
		await window.exponea.identify(email);
		store.commit('setTrackingEmail', email);
		// }
	}

	/**
	 * Update customer attributes in Exponea
	 * @param data
	 */
	update(data) {
		data.cookie_consent = this.#cookieConsent === 'true';
		const payload = {
			customer_ids: {
				registered: this.#getTrackingEmail(),
			},
			properties: data,
		};
		wordpress.exponeaUpdate(payload);
	}

	/**
	 * Add an event to a customer in Exponea
	 * @param event
	 * @param data
	 */
	track(event, data) {
		if (window.exponeaLoaded) {
			data.tracking_engine = 'exponea';
			return exponea.track(event, data);
		}
		data.tracking_engine = 'cote';
		const payload = {
			event_type: event,
			timestamp: DateTime.now().toUnixInteger(),
			properties: data,
			customer_ids: {},
		};

		if (this.#getTrackingEmail() !== null) {
			payload.customer_ids.registered = this.#getTrackingEmail();
		}

		return wordpress.exponeaTrack(payload);
	}

	/**
	 * Gets email to send Exponea tracking email to
	 * @returns {null|string}
	 */
	#getTrackingEmail() {
		return store.state.trackingEmail;
	}
}
export default new Exponea();
