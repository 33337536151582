<template>
	<div>
		<!--  Mobile view  -->
		<div class="xl:hidden w-full h-screen bg-brand top-0 left-0 z-60">
			<restaurant-location-selector
				colour="dark"
				:show-no-locations-message="false"
				parent-classes="w-full xl:w-6/12 bg-brand px-5 py-10"
				@location-selected="selectLocation($event)"
			>
				<template v-slot:header>
					<h2 class="text-cream w-10/12 mx-auto">
						Choose a restaurant
					</h2>
					<h6 class="text-cream w-10/12 mx-auto smaller">
						To see the menus
					</h6>
				</template>
			</restaurant-location-selector>
		</div>
		<!--  Desktop view  -->
		<div v-if="contentLoaded" class="hidden xl:block">
			<mood-board-block
				:fields="
					page.blocks.find((b) => b.acf_fc_layout === 'mood_board')
				"
				ref="moodBoard"
			>
				<div class="sticky-box">
					<restaurant-location-selector
						colour="dark"
						:show-no-locations-message="false"
						parent-classes="lg:w-8/12 2xl:w-7/12 bg-brand px-4 py-5 relative mx-auto lg:top-[125px] lg:mb-[120px] z-30 max-w-[760px]"
						@location-selected="selectLocation($event)"
					>
						<template v-slot:header>
							<h2 class="text-cream m-0">Choose a restaurant</h2>
							<h6 class="text-cream w-10/12 mx-auto smaller m-2">
								To see the menus
							</h6>
						</template>
					</restaurant-location-selector>
				</div>
			</mood-board-block>
		</div>
	</div>
</template>

<script>
import { ChatAlt2Icon, XIcon } from '@heroicons/vue/outline';
import SplashScreen from '@/components/layouts/SplashScreen.vue';
import MoodBoardBlock from '@/components/blocks/MoodBoardBlock.vue';
import RestaurantLocationSelector from '@/components/RestaurantLocationSelector.vue';

export default {
	name: 'MenusView',
	components: {
		SplashScreen,
		ChatAlt2Icon,
		XIcon,
		MoodBoardBlock,
		RestaurantLocationSelector,
	},
	data() {
		return {
			settings: {
				itemsToShow: 2.5,
				snapAlign: 'center',
			},
			breakpoints: {
				1024: {
					itemsToShow: 2.5,
					snapAlign: 'center',
				},
				1280: {
					itemsToShow: 3,
					snapAlign: 'center',
				},
			},
			hover: {
				menu: false,
				chat: false,
			},
			interval: null,
		};
	},
	emits: ['contentLoaded', 'displayPlaceholder'],
	computed: {
		page() {
			return this.$store.getters.getPageContentById(this.$route.meta.id);
		},
		contentLoaded() {
			return (
				this.page &&
				Object.prototype.hasOwnProperty.call(this.page, 'blocks')
			);
		},
		restaurants() {
			return this.$store.state.restaurants;
		},
	},
	methods: {
		async getContent() {
			if (!this.embedded) {
				if (
					!this.$store.getters.checkIfPageContentLoaded(
						this.$route.meta.id
					)
				) {
					if (this.$store.getters.isFirstSplashScreenHidden) {
						this.$emit('displayPlaceholder');
					}
					await this.$store.dispatch('getPage', {
						id: this.$route.meta.id,
					});
					this.$emit('contentLoaded');
				}
			}
		},
		selectLocation(event) {
			const menuCode = this.$store.getters.getMenuCode
				? `?menucode=${this.$store.getters.getMenuCode}`
				: '';
			const link = `${location.protocol}//${
				location.host
			}${this.getBrasserieMenusLink(
				this.restaurants.find((r) => r.ID === event.id).ID
			)}${menuCode}`;

			// we need for force the page to reload as the ten kites script appends code to the header and body .  and we dont need this
			// happening  multiple times.
			window.location.href = link;
		},
		scrollDownSlowly() {
			let currentScrollTop =
				document.documentElement.scrollTop || document.body.scrollTop;
			this.interval = setInterval(() => {
				currentScrollTop =
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				// Stop 1500px before the end of the page
				if (currentScrollTop < document.body.clientHeight - 2000) {
					window.scrollTo(0, currentScrollTop + 1);
				}
			}, 50);
		},
	},
	created() {
		this.getContent();
	},
	mounted() {
		window.scrollTo(0, 0);
	},
	beforeUnmount() {
		clearInterval(this.interval);
	},
};
</script>

<style lang="scss" scoped>
.sticky-box {
	position: sticky;
	top: 16vh;
	bottom: 10px;
	z-index: 30;
	padding: 10px 0 20px 0;
	margin-bottom: 0;
}

.sticky-box > div {
	top: 10px;
	margin-bottom: 0;
}
.c-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: #d7d7cb #0a2036;
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #d7d7cb;
		border-radius: 0px;
	}
}
</style>
