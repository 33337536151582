export default {
	methods: {
		getSlotContents(slot) {
			console.log(this.$slots);
			return this.$slots[slot]
				? this.$slots[slot][0].elm.innerHTML
				: null;
		},

		getSlotRefContents(ref) {
			console.log(ref);
			console.log(this.$refs);
			return this.$refs[ref] ? this.$refs[ref].innerHTML : null;
		},

		emitError(code, data = null) {
			this.$root.$emit('error', { code, data });
		},
	},
};
