import { createStore } from 'vuex';
import wordpress from '@/plugins/wordpress';
import VueCookies from 'vue-cookies';

const store = createStore({
	state() {
		return {
			firstSplashScreenHidden: false,
			cookieConsent: null,
			queryObject: {},
			trackingEmail: null,
			navigation: {},
			endpoints: [],
			instagramFeed: [],
			promotions: [],
			restaurants: [],
			isChatBotOpened: false,
			chatBotRequested: false,
			nearByRestaurants: [],
			restaurantPageData: {},
			menuTiers: [],
			pages: {},
			singleEvent: {},
			globalRestaurantPagesContent: null,
			selectedRestaurant: null,
			promotion: null,
			allRatings: null,
			menucode: null,
			defaultSubMenuCodes: null,
			bookingConfig: {
				stripePublishableKey: false,
				blockInNextMinutes: 0,
				moodBoard: null,
				messages: [],
				slots: {
					threshold: 0,
					before: 0,
					after: 0,
					maximum: 0,
					maximumBefore: 0,
					cutOff: 0,
				},
				reminder: {
					leadDays: 0,
					emailTime: '',
					cutOff: 0,
				},
			},
			wpOptions: {},
		};
	},
	getters: {
		hasChatBotBeenRequested: (state) => state.chatBotRequested,
		isChatBotOpened: (state) => state.isChatBotOpened,
		isFirstSplashScreenHidden: (state) => state.firstSplashScreenHidden,
		getAllRatings: (state) => state.allRatings,
		getRestaurantRating: (state) => (googlePlaceId) => {
			if (!googlePlaceId) return false;
			const theRating = state.allRatings.find(
				(rating) => rating.slug === googlePlaceId.toLowerCase()
			);
			if (!theRating) return false;
			return parseFloat(theRating.acf.rating);
		},
		getMenuByName: (state) => (name) => state.navigation[name],
		getMenuLengthByName: (state) => (name) => state.navigation[name].length,
		getRestaurantById: (state) => (id) =>
			state.restaurants.filter((r) => r.ID === id)[0],
		getRestaurantByName: (state) => (name) =>
			state.restaurants.filter(
				(r) =>
					this.getRestaurantSlug(r.post_title) ===
					this.getRestaurantSlug(name)
			)[0],
		getPageContentById: (state) => (id) => state.pages[id],
		checkIfPageContentLoaded: (state) => (id) =>
			state.pages.hasOwnProperty(id),
		checkIfRestaurantContentLoaded: (state) => (id) =>
			state.restaurantPageData.hasOwnProperty(id),

		getRestaurantContentById: (state) => (id) =>
			state.restaurantPageData[id],

		getBookingWidgetConfig:
			(state) =>
			(key = null) => {
				if (key) {
					return state.bookingConfig[key];
				}
				return state.bookingConfig;
			},
		getEndpoint:
			(state) =>
			(key = null) => {
				if (key) {
					return state.endpoints[key];
				}
				return state.endpoints;
			},
		getWpOptions: (state) => (wpOptions) => state.wpOptions,
		getNearbyRestaurants: (state) => state.nearByRestaurants,
		get404PageData: (state) => state.page404,
		getMenuCode: (state) => state.menucode,
		getDefaultSubMenuCodes: (state) => state.defaultSubMenuCodes,
	},
	mutations: {
		// synchronous operations only
		hideFirstSplashScreen(state) {
			state.firstSplashScreenHidden = true;
		},
		initMenus(state, nav) {
			state.navigation = nav;
		},
		initEndpoints(state, endpoints) {
			state.endpoints = endpoints;
		},
		initInstagram(state, feed) {
			state.instagramFeed = feed;
		},
		initPromotions(state, promotions) {
			state.promotions = promotions;
		},
		initRestaurants(state, restaurants) {
			state.restaurants = restaurants;
		},

		initMenuCode(state, menucode) {
			state.menucode = menucode;
		},

		initRestaurantPageData(state, restaurants) {
			restaurants.forEach((restaurant) => {
				state.restaurantPageData[restaurant.id] = {
					acf: restaurant.acf,
					id: restaurant.id,
					nearbyRestaurants: restaurant.nearbyRestaurants,
					openingTimes: restaurant.openingTimes,
					review: restaurant.review,
					title: restaurant.title.rendered,
				};
			});
		},
		initAllRatings(state, ratings) {
			state.allRatings = ratings;
		},
		initGlobalRestaurantPagesContent(state, content) {
			state.globalRestaurantPagesContent = content;
		},

		initPage(state, page) {
			state.pages[page.page_id] = {};
			state.pages[page.page_id].title = page.title.rendered;
			state.pages[page.page_id].blocks = page.acf.blocks;
			state.pages[page.page_id].yoastData = page.yoast_head_json;
		},
		initEvents(state, events) {
			state.events = events;
		},
		initSingleEvent(state, event) {
			state.singleEvent = event;
		},
		chatBotOpened(state) {
			state.isChatBotOpened = true;
		},
		initPages(state, pages) {
			pages.forEach((page) => {
				state.pages[page.id] = {
					title: page.title.rendered,
					blocks: page.acf.blocks,
				};
			});
		},

		initBookingWidgetConfig(state, config) {
			state.bookingConfig.blockInNextMinutes = parseInt(
				config.bw_block_minutes
			);
			state.bookingConfig.slots.before = parseInt(
				config.bw_available_threshold_before
			);
			state.bookingConfig.slots.after = parseInt(
				config.bw_available_threshold_after
			);
			state.bookingConfig.slots.cutOff = parseInt(config.bw_cc_cutoff);
			state.bookingConfig.slots.maximumBefore = parseInt(
				config.bw_maximum_slots_before
			);
			state.bookingConfig.slots.maximum = parseInt(
				config.bw_maximum_slots
			);
			state.bookingConfig.slots.threshold = parseInt(
				config.bw_result_obtained_threshold
			);
			state.bookingConfig.reminder.leadDays = parseInt(
				config.bw_reminder_days
			);
			state.bookingConfig.reminder.emailTime = config.bw_reminder_time;
			state.bookingConfig.reminder.cutOff = config.bw_reminder_cutoff;
			state.bookingConfig.moodBoard = config.bw_mood_board;
			state.bookingConfig.stripePublishableKey =
				config.bw_stripe_publishable_key;
			state.restaurants = config.restaurants;
		},
		initBookingMessages(state, messagesArray) {
			state.bookingConfig.messages = messagesArray || [];
		},
		initWpOptions(state, wpOptions) {
			state.wpOptions = wpOptions;
		},
		initMenuTiers(state, menuTiers) {
			state.menuTiers = menuTiers;
		},
		initDefaultSubMenuCodes(state, defaultSubMenuCodes) {
			state.defaultSubMenuCodes = defaultSubMenuCodes;
		},
		initPromotion(state, promotion) {
			state.promotion = promotion;
		},
		init404Page(state, page404) {
			state.page404 = page404;
		},
		setSelectedRestaurant(state, id) {
			state.selectedRestaurant = id;
		},
		setCookieConsent(state, consent) {
			state.cookieConsent = consent;
		},
		setTrackingEmail(state, email) {
			state.trackingEmail = email;
		},
		setNearbyRestaurants(state, restaurantsArray) {
			state.nearByRestaurants = restaurantsArray;
		},
		requestChatBot(state) {
			state.chatBotRequested = true;
		},
	},
	actions: {
		async requestChatBot(context) {
			context.commit('requestChatBot');
		},
		async getData(context) {
			// Data is a global variable called in a script tag within index.html
			context.commit('initMenus', data.allNavigation);
			context.commit('initEndpoints', data.endpoints);
			context.commit('initInstagram', data.instagramFeed);
			context.commit('initRestaurants', data.restaurants);
			context.commit('initPromotions', data.promotions);
			context.commit(
				'initGlobalRestaurantPagesContent',
				data.globalBrasseriePagesContent
			);
			context.commit('initBookingWidgetConfig', data.bookingConfig);
			context.commit('initBookingMessages', data.bookingMessages);
			context.commit('initWpOptions', data.wpOptions);
			context.commit('init404Page', data['404Page']);
			context.commit('initAllRatings', data.allRatings);
			context.commit('initDefaultSubMenuCodes', data.defaultSubMenuCodes);
		},

		async getRestaurant(context, payload) {
			await wordpress
				.getPost(payload.id)
				.then((r) => r.json())
				.then((resp) => {
					context.commit('initRestaurantPageData', [resp]);
				});
		},

		async getPage(context, payload) {
			await wordpress
				.getPage(payload.id)
				.then((r) => r.json())
				.then((resp) => {
					resp.page_id = payload.id;
					context.commit('initPage', resp);
				});
		},

		async getAllEvents(context) {
			await wordpress
				.getAllEvents()
				.then((r) => r.json())
				.then((resp) => {
					context.commit('initEvents', resp.events);
				});
		},

		getFullPageAndRestaurantData(context) {
			wordpress
				.getFullPageAndRestaurantData()
				.then((r) => r.json())
				.then((resp) => {
					context.commit('initPages', resp.pages);
					context.commit('initRestaurantPageData', resp.restaurants);
				});
		},
		getFullPageData(context) {
			wordpress
				.getFullPageData()
				.then((r) => r.json())
				.then((resp) => {
					context.commit('initPages', resp.pages);
				});
		},

		async getEventById({ commit }, id) {
			try {
				const event = await wordpress.getEventById(id);
				commit('initSingleEvent', event);
			} catch (error) {
				console.error(error);
			}
		},
		getMenuTiers(context) {
			return new Promise((resolve, reject) => {
				wordpress
					.getMenuTiers()
					.then((r) => r.json())
					.then((resp) => {
						context.commit('initMenuTiers', resp);
						resolve({ menuTiers: context.state.menuTiers });
					});
			});
		},
		getPromotionByCode(context, queryObject) {
			return new Promise((resolve, reject) => {
				wordpress
					.getPromotionByCode(queryObject)
					.then((r) => r.json())
					.then((resp) => {
						context.commit('initPromotion', resp);
						resolve();
					});
			});
		},
		getCookieConsent(context) {
			return new Promise((resolve, reject) => {
				if (!VueCookies.isKey('cookie_consent')) {
					context.commit('setCookieConsent', null);
					resolve({ consent: null });
				}
				context.commit(
					'setCookieConsent',
					VueCookies.get('cookie_consent')
				);
				resolve({ consent: context.state.cookieConsent });
			});
		},
	},
});

export default store;
