export default {
	data() {
		return {
			currentValidations: '',
		};
	},

	computed: {
		isValidating() {
			return this.currentValidations !== '';
		},
	},

	methods: {
		/**
		 * Expected
		 * {
		 *   field: 'email'
		 *   value: true|false
		 * }
		 *
		 * We use a string - just in case you want to watch currentValidations
		 *
		 * @param data
		 */
		validating(data) {
			// remove it
			this.currentValidations = this.currentValidations.replace(
				`~${data.field}`,
				''
			);

			// add it?
			if (data.status === true) {
				this.currentValidations += `~${data.field}`;
			}
		},
	},
};
