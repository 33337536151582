class LocalStore {
	set(key, value, seconds) {
		const now = new Date();

		localStorage.setItem(
			key,
			JSON.stringify({
				expires: this.now() + seconds,
				data: value,
			})
		);
	}

	now() {
		return Math.round(new Date().getTime() / 1000);
	}

	get(key) {
		const itemStr = localStorage.getItem(key);
		if (!itemStr) {
			return null;
		}
		const item = JSON.parse(itemStr);
		if (this.now() > item.expires) {
			localStorage.removeItem(key);
			return null;
		}
		return item.data;
	}
}

export default new LocalStore();
