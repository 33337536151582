import store from '@/store/store';

class Wordpress {
	#baseUrl;

	#baseUrlEvents;

	#apiEndpoint;

	#username;

	#password;

	#headers;

	constructor() {
		this.#baseUrl = `${process.env.VUE_APP_SITE_URL}/wp-json/wp/v2/`;
		this.#baseUrlEvents = `${process.env.VUE_APP_SITE_URL}/wp-json/tribe/events/v1/`;
		this.#apiEndpoint = `${process.env.VUE_APP_SITE_URL}/api/`;
		this.#username = 'admin';
		this.#password = process.env.VUE_APP_SITE_AUTH;
		this.#headers = new Headers();
		this.#headers.append(
			'Authorization',
			`Basic ${this.#username}:${this.#password}`
		);
		this.#headers.append('Content-Type', 'application/json');
	}

	getEndpoints() {
		return this.#call('endpoints/');
	}
	
	getFullPageAndRestaurantData() {
		return this.#call(`all-page-restaurant-data/`);
	}

	getFullPageData() {
		return this.#call(`all-page-data/`);
	}
	
	// Get all events
	getAllEvents() {
		return this.#callEvents('events/?per_page=36');
	}

	async getEventById(id) {
		try {
			const response = await this.#callEvents(`events/${id}`);
			if (!response.ok) {
				window.location.href = '/404';
				// throw new Error(response.statusText);
			}
			const eventData = await response.json();
			const event = eventData;
			return event;
		} catch (error) {
			console.error('Error fetching event data:', error);
		}
	}

	/**
	 * Gets a WordPress page with all content
	 * @param id
	 * @returns {Promise<Response>}
	 */
	getPage(id) {
		return this.#call('single-page-data/', 'POST', id);
	}

	/**
	 * Gets a WordPress restaurant with all content
	 * @param id
	 * @returns {Promise<Response>}
	 */
	getPost(id) {
		return this.#call(`restaurants/${id}`);
	}

	/**
	 * Get restaurants by location
	 * @param lat
	 * @param lng
	 * @returns {Promise<Response>}
	 */
	getRestaurantsByDistance(lat, lng) {
		return this.#call(`brasseries/distance/${lat}/${lng}`);
	}

	/**
	 * Gets menu tiers
	 * @returns {Promise<Response>}
	 */
	getMenuTiers() {
		return this.#call('menu-tiers');
	}

	getDefaultSubMenuCodes() {
		return this.#call('default-sub-menu-codes');
	}

	/**
	 * Get all redirections posts
	 * @returns {Promise<Response>}
	 */
	async getRedirections() {
		return this.#call('redirections');
	}

	/**
	 * Returns Google Review custom post type that applies to specified restaurant
	 * @param id
	 * @returns {Promise<Response>}
	 */
	getGoogleReviewForRestaurant(id) {
		return this.#call(`google-review/${id}`);
	}

	/**
	 * Gets a promotion from WordPress (usually used in booking widget)
	 * @param code
	 * @returns {Promise<Response>}
	 */
	getPromotionByCode(queryObject) {
		return this.#call('promotions', 'POST', queryObject);
	}

	/**
	 * Track event in Exponea via backend
	 * @param payload
	 * @returns {Promise<Response>}
	 */
	exponeaTrack(payload) {
		return this.#call('exponea/track', 'POST', payload);
	}

	/**
	 * Update a customer in Exponea via backend
	 * @param payload
	 * @returns {Promise<Response>}
	 */
	exponeaUpdate(payload) {
		return this.#call('exponea/update', 'POST', payload);
	}

	/**
	 * Gets all routes (pages) from WordPress used in Vue router
	 * @returns {Promise<any>}
	 */
	async getRoutes() {
		return data.routes;
	}

	errorLog(config) {
		if (typeof config.metaData === 'object') {
			// hack to strip off anything unwanted.
			config.metaData = JSON.parse(JSON.stringify(config.metaData));
			Object.keys(config.metaData).forEach(
				(item) => (data[item] = config.metaData[item])
			);
		}

		if (typeof config.errorData === 'object') {
			// hack to strip off anything unwanted.
			config.errorData = JSON.parse(JSON.stringify(config.errorData));
			Object.keys(config.errorData).forEach(
				(item) => (data[item] = config.errorData[item])
			);
		}

		config.location = window.location.href;
		config.user_agent = navigator.userAgent;

		console.log('errorDataSentToWp', config);

		return this.#call('log_frontend_error', 'POST', config);
	}

	/**
	 * Create a quandoo booking through wordpress
	 * @returns {Promise<Response>}
	 * @param {Object} args
	 */
	quandooCreateReservation(args) {
		return this.#api('quandoo/create_reservation', 'POST', args);
	}

	/**
	 * Retrieve a reservation from Quandoo through wordpress
	 * @returns {Promise<Response>}
	 * @param {String} id
	 */
	quandooGetReservation(id) {
		return this.#api('quandoo/get_reservation', 'POST', {
			reservation_id: id,
		});
	}

	/**
	 * Cancel a quandoo booking through wordpress
	 * @returns {Promise<Response>}
	 * @param reservationId
	 */
	quandooCancelReservation(reservationId, email) {
		return this.#api('quandoo/cancel_reservation', 'POST', {
			reservation_id: reservationId,
			email,
		});
	}

	/**
	 * Amend a quandoo booking through wordpress
	 * @returns {Promise<Response>}
	 * @param {Object} data
	 */
	quandooAmendReservation(data) {
		return this.#api('quandoo/amend_reservation', 'POST', data);
	}

	/**
	 * Create a quandoo enquiry through wordpress
	 * @returns {Promise<Response>}
	 * @param {Object} args
	 */
	quandooCreateEnquiry(args) {
		return this.#api('quandoo/create_enquiry', 'POST', args);
	}

	/**
	 * Gets available time slots based on restaurant ID, capacity, date, time, and if it's Open Table or Quandoo
	 * @returns {Promise<Response>}
	 * @param merchantId
	 * @param capacity
	 * @param date
	 * @param time
	 * @param isOpenTable
	 */
	async getAvailableTimesForReservation(
		merchantId,
		capacity,
		date,
		time,
		isOpenTable
	) {
		const response = await this.#api('quandoo/get_times', 'POST', {
			merchant_id: merchantId,
			capacity,
			date,
			time,
			isOpenTable: isOpenTable.toString(),
		});
		return response;
	}

	/**
	 *
	 * @returns promise
	 */
	exponeaGetCustomerEvents(args) {
		return this.#api('exponea/customer_events', 'POST', args);
	}

	exponeaCustomer(args) {
		return this.#api('exponea/customer', 'POST', args);
	}

	exponeaHasEnteredCompetition(args) {
		return this.#api('exponea/entered_competition', 'POST', args);
	}

	exponeaConsents(args) {
		return this.#api('exponea/consents', 'POST', args);
	}

	/**
	 * Cookie Banner Tracking
	 * @param {String} args.trackingStatus alreadyAccepted, accepted, rejected, alreadyRejected, null
	 */
	countTrackingAcceptance(args) {
		return this.#call('tracking/count', 'POST', args);
	}

	/**
	 * Private function that makes the ajax call to WordPress backend
	 * @param endpoint
	 * @param method
	 * @param data
	 * @returns {Promise<Response>}
	 */
	#call(endpoint, method = 'GET', data = null) {
		const args = {
			headers: this.#headers,
		};
		if (method !== 'GET') {
			args.method = method;
			if (data !== null) {
				args.body = JSON.stringify(data);
			}
		}
		return fetch(this.#baseUrl + endpoint, args);
	}

	#callEvents(endpoint, method = 'GET', data = null) {
		const args = {
			headers: this.#headers,
		};
		if (method !== 'GET') {
			args.method = method;
			if (data !== null) {
				args.body = JSON.stringify(data);
			}
		}
		return fetch(this.#baseUrlEvents + endpoint, args);
	}

	/**
	 * private function to mak an ajax call
	 * @param endpoint
	 * @param method
	 * @param data
	 * @returns {Promise<Response>}
	 */
	#api(endpoint, method = 'GET', data = null) {
		const args = {
			method,
		};
		if (method !== 'GET') {
			if (data !== null) {
				args.body = JSON.stringify(data);
			}
		}
		return fetch(this.#apiEndpoint + endpoint, args);
	}
}

export default new Wordpress();
