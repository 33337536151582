<template>
	<div
		class="bg-cream w-full h-screen"
		:class="{ 'max-h-0': noHeight }"
	></div>
</template>

<script>
export default {
	name: 'BlankView',
	created() {
		if (this.$route.path.indexOf('book-a-table') >= 0) {
			this.noHeight = true;
		}
	},
};
</script>

<style scoped></style>
