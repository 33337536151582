<template>
	<div
		class="px-5 lg:px-28 xl:px-40 2xl:px-64 py-10 lg:py-20 bg-cream flex flex-col lg:flex-row justify-between items-center"
	>
		<div class="text-left mb-14 lg:mb-0">
			<h4>{{ get404PageData['404_pre_heading'] }}</h4>
			<h1>{{ get404PageData['404_heading'] }}</h1>
			<h4>{{ get404PageData['404_text'] }}</h4>
			<a
				:href="get404PageData['404_button_link']"
				class="standard-button bg-brand text-cream border border-cream mt-16"
				>{{ get404PageData['404_button_text'] }}</a
			>
		</div>
		<div>
			<img
				loading="lazy"
				src="@/assets/bike_drawing.png"
				class="w-10/12 lg:w-auto mx-auto max-w-full"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'NotFoundView',
	computed: {
		...mapGetters(['get404PageData']),
	},
};
</script>

<style scoped></style>
