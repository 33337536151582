import { Buffer } from 'buffer';
import { DateTime } from 'luxon';

const NOT_FOUND = '/404/';

export default {
	methods: {
		convertNewLinesToBreaks(string) {
			return string.replace(/(?:\r\n|\r|\n)/g, '<br />');
		},

		getGoogleMapsUrl(query) {
			return `https://www.google.com/maps/search/?api=1&query=${encodeURI(
				query
			)}`;
		},

		getFacebookShareUrl(query) {
			return `http://www.facebook.com/share.php?u=${encodeURIComponent(
				query
			)}`;
		},

		getTwitterShareUrl(query) {
			return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
				query
			)}`;
		},

		generateUrl(path) {
			if (path.indexOf(process.env.VUE_APP_SITE_URL) >= 0) {
				if (path.endsWith('.pdf')) {
					return path;
				}
				return path.replace(/^.*\/\/[^/]+/, '');
			}
			return path;
		},

		getBrasserieLink(brasserieId) {
			let link = null;

			this.$store.state.restaurants.forEach((restaurant) => {
				if (brasserieId === restaurant.ID) {
					link = restaurant.link;
				}
			});

			return link ?? NOT_FOUND;
		},

		getBrasserieMenusLink(brasserieId) {
			let link = null;

			this.$store.state.restaurants.forEach((restaurant) => {
				if (brasserieId === restaurant.ID) {
					link = restaurant.menusLink;
				}
			});

			return link ?? NOT_FOUND;
		},

		filterByLocationString(restaurants) {
			return this.locationSearchString !== ''
				? restaurants.filter((r) => {
						const postTitle = r.post_title?.toLowerCase() || '';
						const townCity = r.town_city?.toLowerCase() || '';
						const address =
							r.location?.address?.toLowerCase() || '';
						const searchString =
							this.locationSearchString.toLowerCase();

						return (
							postTitle.includes(searchString) ||
							townCity.includes(searchString) ||
							address.includes(searchString)
						);
				  })
				: restaurants;
		},

		getButtonStyle(colour) {
			if (colour === 'white') {
				return 'bg-white text-brand border border-white';
			}
			if (colour === 'cream') {
				return 'bg-cream text-brand border border-cream';
			}
			if (colour === 'yellow') {
				return 'bg-mustard text-brand border border-mustard';
			}
			if (colour === 'navy') {
				return 'bg-brand text-cream border border-brand';
			}
			if (colour === 'copper') {
				return 'bg-copper text-white border border-copper';
			}
			if (colour === 'dark-green') {
				return 'bg-green text-white border border-green';
			}
			if (colour === 'transparent') {
				return 'bg-transparent border border-brand text-brand';
			}
		},
		getBackgroundColour(colour) {
			if (colour === 'white') {
				return 'bg-white';
			}
			if (colour === 'brown') {
				return 'bg-brown';
			}
			if (colour === 'navy') {
				return 'bg-brand';
			}
			if (colour === 'green') {
				return 'bg-herb';
			}
			if (colour === 'dark-green') {
				return 'bg-green';
			}
			if (colour === 'copper') {
				return 'bg-copper';
			}
			if (colour === 'yellow') {
				return 'bg-mustard';
			}
			if (colour === 'grainy') {
				return 'bg-noise-pale';
			}
			if (colour === 'g-grainy') {
				return 'bg-herb c-grainy';
			}
			if (colour === 'cream') {
				return 'bg-cream';
			}
			if (colour === 'transparent') {
				return 'bg-transparent border border-brand';
			}
		},
		getMarqueeScrollDuration(string) {
			return string.length * 1;
		},
		uuidV4() {
			return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
				(
					c ^
					(crypto.getRandomValues(new Uint8Array(1))[0] &
						(15 >> (c / 4)))
				).toString(16)
			);
		},
		compressUuid(uuid) {
			return Buffer.from(uuid.replace(/-/g, ''), 'hex')
				.toString('base64')
				.replace(/\+/g, '-')
				.replace(/\//g, '_')
				.replace(/=+$/, '');
		},
		uncompressUuid(base64) {
			base64 += Array(5 - (base64.length % 4)).join('=');
			base64 = base64.replace(/-/g, '+').replace(/_/g, '/');
			const hex = Buffer.from(base64, 'base64').toString('hex');
			return `${hex.substring(0, 8)}-${hex.substring(
				8,
				12
			)}-${hex.substring(12, 16)}-${hex.substring(
				16,
				20
			)}-${hex.substring(20)}`;
		},
		isExternalLink(url) {
			const link = document.createElement('a');
			link.href = this.generateUrl(url);
			const result = link.host !== window.location.host;
			link.remove();
			return result;
		},
		getTextColour(backgroundColour) {
			if (
				backgroundColour === 'navy' ||
				backgroundColour === 'dark-green' ||
				backgroundColour === 'transparent'
			) {
				return 'text-cream';
			}
			if (backgroundColour === 'copper') {
				return 'text-white';
			}
			return 'text-brand';
		},
		requestLiveChatPopup() {
			// Load the chat script here
			const hasChatBotAlreadyBeenRequested =
				this.$store.getters.hasChatBotBeenRequested;

			if (!hasChatBotAlreadyBeenRequested) {
				this.$store.dispatch('requestChatBot');
			}

			const hasChatBotLoaded = document.getElementById('fbots-wdgt');

			if (!hasChatBotLoaded) {
				setTimeout(() => this.requestLiveChatPopup(), 100);
			} else {
				this.openLiveChatPopup();
			}
		},
		openLiveChatPopup() {
			document.getElementById('fbots-wdgt').classList.add('open');
			// window.fcWidget.open(); window.fcWidget.show()
			document
				.getElementById('header-chat-icon-container')
				.classList.remove('chat-closed');
			document
				.getElementById('header-chat-icon-container')
				.classList.add('chat-open');
		},
		closeLiveChatPopup() {
			document.getElementById('fbots-wdgt')?.classList.remove('open');
			// window.fcWidget.open(); window.fcWidget.show()
			document
				.getElementById('header-chat-icon-container')
				.classList.add('chat-closed');
			document
				.getElementById('header-chat-icon-container')
				.classList.remove('chat-open');
		},
		getRestaurantSlug(name) {
			return name
				.toLowerCase()
				.replaceAll('.', '')
				.replaceAll(' - ', ' ')
				.replaceAll("'", '')
				.replaceAll(' ', '-');
		},
		async cropImageByAspectRatio(url, aspectRatio) {
			return new Promise((resolve) => {
				// this image will hold our source image data
				const inputImage = new Image();
				inputImage.crossOrigin = '*';

				// we want to wait for our image to load
				inputImage.onload = () => {
					// let's store the width and height of our image
					const inputWidth = inputImage.naturalWidth;
					const inputHeight = inputImage.naturalHeight;

					// get the aspect ratio of the input image
					const inputImageAspectRatio = inputWidth / inputHeight;

					// if it's bigger than our target aspect ratio
					let outputWidth = inputWidth;
					let outputHeight = inputHeight;
					if (inputImageAspectRatio > aspectRatio) {
						outputWidth = inputHeight * aspectRatio;
					} else if (inputImageAspectRatio < aspectRatio) {
						outputHeight = inputWidth / aspectRatio;
					}

					// calculate the position to draw the image at
					const outputX = (outputWidth - inputWidth) * 0.5;
					const outputY = (outputHeight - inputHeight) * 0.5;

					// create a canvas that will present the output image
					const outputImage = document.createElement('canvas');

					// set it to the same size as the image
					outputImage.width = outputWidth;
					outputImage.height = outputHeight;

					// draw our image at position 0, 0 on the canvas
					const ctx = outputImage.getContext('2d');
					ctx.drawImage(inputImage, outputX, outputY);
					resolve(outputImage.toDataURL('image/jpeg', 0.9));
				};
				// start loading our image
				inputImage.src = url;
			});
		},
		getOrdinalDate(date) {
			const suffixes = ['th', 'st', 'nd', 'rd'];
			const remainder = date % 100;
			return (
				date +
				(suffixes[(remainder - 20) % 10] ||
					suffixes[remainder] ||
					suffixes[0])
			);
		},
		getDateWithTimeFormat(date) {
			const jsDate = new Date(date);
			const luxonDate = DateTime.fromJSDate(jsDate);

			return `${luxonDate.toFormat('cccc')} ${this.getOrdinalDate(
				luxonDate.day
			)} ${luxonDate.toFormat('MMMM h:mm a')}`;
		},
		getDateOnlyFormat(date) {
			const jsDate = new Date(date);
			const luxonDate = DateTime.fromJSDate(jsDate);

			return `${luxonDate.toFormat('cccc')} ${this.getOrdinalDate(
				luxonDate.day
			)} ${luxonDate.toFormat('MMMM')}`;
		},
		getHourOnlyFormat(date) {
			const jsDate = new Date(date);
			const luxonDate = DateTime.fromJSDate(jsDate);

			return `${luxonDate.toFormat('h:mm a')}`;
		},
		isSameDate(firstDate, secondDate) {
			const startJsDate = new Date(firstDate);
			const endJsDate = new Date(secondDate);
			const startLuxonDate = DateTime.fromJSDate(startJsDate);
			const endLuxonDate = DateTime.fromJSDate(endJsDate);

			return startLuxonDate.hasSame(endLuxonDate, 'day');
		},
		getDateTimeRange(startDate, endDate) {
			const startJsDate = new Date(startDate);
			const endJsDate = new Date(endDate);
			const isSameDate = this.isSameDate(startDate, endDate);
			const startLuxonDate = DateTime.fromJSDate(startJsDate);
			const endLuxonDate = DateTime.fromJSDate(endJsDate);

			const renderedEndDate = isSameDate
				? endLuxonDate.toFormat('h:mm a')
				: `
        ${endLuxonDate.toFormat('cccc')} ${this.getOrdinalDate(
						endLuxonDate.day
				  )} ${endLuxonDate.toFormat('MMMM h:mm a')}`;

			return `${startLuxonDate.toFormat('cccc')} ${this.getOrdinalDate(
				startLuxonDate.day
			)} ${startLuxonDate.toFormat('MMMM h:mm a')} - ${renderedEndDate}`;
		},
	},
};
