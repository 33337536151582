import { createRouter, createWebHistory } from 'vue-router';
import wordpress from '@/plugins/wordpress';
import DefaultView from '@/views/DefaultView.vue';
import MenusView from '@/views/MenusView.vue';
import LocationsView from '@/views/LocationsView.vue';
import EventsView from '@/views/EventsView.vue';
import SingleEventView from '@/views/SingleEventView.vue';
import RestaurantView from '@/views/RestaurantView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import MenuView from '@/views/MenuView.vue';
import BlankView from '@/views/BlankView.vue';
import SteakSoiree from '@/components/iframe/SteakSoiree.vue';

const setComponent = (routeName) => {
	if (routeName === 'menus') {
		return MenusView;
	}
	if (routeName === 'locations') {
		return LocationsView;
	}
	if (routeName === 'events') {
		return Events;
	}
	if (routeName.indexOf('côte brasserie menu') >= 0) {
		return MenuView;
	}
	if (routeName.indexOf('côte brasserie') >= 0) {
		return RestaurantView;
	}
	if (routeName === 'not found') {
		return NotFoundView;
	}
	return DefaultView;
};

const checkForRedirect = async (to) =>
	await wordpress
		.getRedirections()
		.then((r) => r.json())
		.then((resp) => {
			const redirect = resp.find(
				(r) => r.post_title.toLowerCase() === to.fullPath.toLowerCase()
			);
			if (redirect !== undefined) {
				return redirect;
			}
			return null;
		});

const { routes } = data;

routes.forEach((r) => {
	r.component = setComponent(r.name);
});

routes.push({
	path: '/book-a-table',
	name: 'book a table',
	component: BlankView,
	meta: {
		title: 'Côte Restaurants - Book a table',
	},
});
routes.push({
  path: '/the-great-british-steak-survey',
  name: 'The Great British Steak Survey',
  component: SteakSoiree,
  meta: {
    title: 'Côte Restaurants - The Great British Steak Survey',
    hideFooter: true,
    hideBookingWidget: true,
  },
});
routes.push({
	path: '/events',
	name: 'events',
	component: EventsView,
	meta: {
		title: 'Côte Restaurants - Events',
	},
});
routes.push({
	path: '/events/:id',
	name: 'event',
	component: SingleEventView,
	meta: {
		title: 'Côte Restaurants - Event Details',
	},
});
routes.push({
	path: '/book-a-table/cancel',
	name: 'cancel a reservation',
	component: BlankView,
	meta: {
		title: 'Côte Restaurants - cancel a reservation',
	},
});
routes.push({
	path: '/book-a-table/manage-bookings',
	name: 'manage bookings',
	component: BlankView,
	meta: {
		title: 'Côte Restaurants - manage bookings',
	},
});
routes.push({
	path: '/book-a-table/confirm-card-details',
	name: 'confirm card details',
	component: BlankView,
	meta: {
		title: 'Côte Restaurants - confirm card details',
	},
});
routes.push({
	path: '/book-a-table/reconfirm-booking',
	name: 'reconfirm a reservation',
	component: BlankView,
	meta: {
		title: 'Côte Restaurants - reconfirm a reservation',
	},
});
routes.push({
	path: '/:pathMatch(.*)*',
	name: 'not found initial',
	beforeEnter: (to, from, next) => {
		checkForRedirect(to).then((link) => {
			if (link === null) {
				next('/404');
			}

			switch (link.to) {
				case 'internal':
					next(`/${link.internal}`);
					break;
				case 'home':
					next('/');
					break;
				case 'external':
					window.location.href = link.external;
					break;
				default:
					window.location.href = link.external;
			}
		});
	},
});
// Short links for booking amend URLs
routes.push({
	path: '/a/:id',
	redirect: (to) => ({
		name: 'book a table',
		query: { amend: to.params.id },
	}),
});
// Short links for booking cancel URLs
routes.push({
	path: '/c/:id',
	redirect: (to) => ({
		name: 'book a table',
		query: { cancel: to.params.id },
	}),
});

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	navigationFallback: {
		rewrite: '/index.html',
		exclude: ['/images/*.{png,jpg,gif}', '/css/*'],
	},
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}
		return {
			top: 0,
			left: 0,
		};
	},
});

// This callback runs before every route change, including on page load. To update page title and meta tags
router.beforeEach((to, from, next) => {
	if (!from.name && !from.matched.length && to.fullPath !== '/404') {//on initial page load only
		gtag('event', 'page_view', {
			page_title: to.name,
			page_path: window.location.pathname,
			page_location: window.location.href,
		});
	}

	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);
	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);
	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	let metaDesc =
		nearestWithTitle?.meta?.desc ??
		'At Côte, we’re all about attention to detail. From the first crumble of the croissant to the expertly grilled Côte de Boeuf, we put our all into making every day more special.';

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
		const url =
			window.location.origin + nearestWithTitle.path.replace('//', '/');
		document.getElementById('canonical').setAttribute('href', url);
		document
			.querySelector('meta[name="description"]')
			.setAttribute('content', metaDesc);
	} else if (previousNearestWithMeta) {
		const url = window.location.origin + previousNearestWithMeta.path;
		document.getElementById('canonical').setAttribute('href', url);
		document.title = previousNearestWithMeta.meta.title;
		metaDesc = previousNearestWithMeta.meta.desc;
		document
			.querySelector('meta[name="description"]')
			.setAttribute('content', metaDesc);
	}
	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
		(el) => el.parentNode.removeChild(el)
	);
	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();
	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement('meta');
			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});
			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');
			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});

export default router;
