export default {
	props: {
		value: {},
		required: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			default() {
				return [];
			},
		},
		utils: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		field: {
			type: String,
		},
		lightTheme: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			open: false,
			focusIndex: 0,
		};
	},

	computed: {
		total() {
			return this.items.length - 1;
		},
	},

	watch: {
		open() {
			this.focusIndex = 0;
		},
	},

	methods: {
		toggleOpen() {
			this.open = this.disabled ? false : !this.open;
		},

		documentClicked(e) {
			if (e.target === this.$refs.input) {
				this.toggleOpen();
			} else {
				this.open = false;
			}
		},

		scrollList() {
			if (this.$el.getElementsByClassName('input-select-item')[1]) {
				const h =
					this.$el.getElementsByClassName('input-select-item')[1]
						.offsetHeight;
				const s = this.focusIndex > 2 ? h * (this.focusIndex - 2) : 0;
				this.$refs.list.scrollTop = s;
			}
		},

		downPressed() {
			this.focusIndex += this.focusIndex < this.total ? 1 : 0;
			this.scrollList();
		},

		upPressed() {
			this.focusIndex -= this.focusIndex > 0 ? 1 : 0;
			this.scrollList();
		},

		inputKeyDown(e) {
			if (this.open) {
				// down
				if (e.keyCode === 40) {
					e.preventDefault();
					this.downPressed();
					// up
				} else if (e.keyCode === 38) {
					e.preventDefault();
					this.upPressed();
					// enter
				} else if (e.keyCode === 13) {
					e.preventDefault();
					this.enterPressed();
				}
				// escape
				if (e.keyCode === 27) {
					this.open = false;
				}
			} else if (e.keyCode !== 9) {
				this.toggleOpen();
			}
		},

		itemBlur(i) {
			if (i === this.items.length - 1) {
				this.open = false;
			}
		},
	},

	mounted() {
		window.addEventListener('click', this.documentClicked);
	},

	beforeDestroy() {
		window.removeEventListener('click', this.documentClicked);
	},
};
